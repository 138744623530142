
.Contact {
  display: block;
  margin-bottom: 80px;

  @media (max-width: 690px) {
    margin-bottom: 128px;
  }

  @media (max-width: 550px) {
    margin-bottom: 88px;
  }

  &__content {
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    @include clear;

    @media (max-width: 860px) {
      position: relative;
      padding: 12px 0;
      height: 200px;
    }

    @media (max-width: 430px) {
      height: auto;
      padding-bottom: 0;
    }
  }

  &__column {
    display: block;
    width: 25%;
    float: left;
    height: 128px;

    @media (max-width: 860px) {
      width: 50%;
      float: none;
      height: auto;
    }

    @media (max-width: 590px) {
      width: 66%;
    }

    @media (max-width: 430px) {
      width: 100%;
    }

    &--grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      border-left: 1px solid rgba(0, 0, 0, 0.08);
      padding: 45px 40px;

      @media (max-width: 1010px) {
        padding: 45px 24px;
      }

      @media (max-width: 860px) {
        width: 50%;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        padding: 80px;
      }

      @media (max-width: 720px) {
        padding: 80px 56px;
      }

      @media (max-width: 590px) {
        width: 33%;
        display: block;
        padding: 8px 0;
      }

      @media (max-width: 430px) {
        position: static;
        width: 100%;
        height: auto;
        display: flex;
        border-left: none;
        padding: 16px 32px;
        margin-top: 12px;
        border-top: 1px solid rgba(0, 0, 0, 0.08);
      }
    }
  }

  &__social {
    display: block;
    width: 40px;
    height: 40px;
    text-indent: -9999px;
    margin: auto;

    &Column {
      width: 40px;

      @media (max-width: 590px) {
        margin: 16px auto;
      }

      @media (max-width: 430px) {
        margin: 0;
      }
    }

    &--instagram {
      background: url("../../assets/icons/instagram.png") no-repeat center;
      background-size: 40px 40px;

      @media(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background: url("../../assets/icons/instagram@2x.png") no-repeat center;
        background-size: 40px 40px;
      }
    }

    &--twitter {
      background: $ic-twitter no-repeat center;
      background-size: 40px 40px;
    }

    &--facebook {
      background: $ic-facebook no-repeat center;
      background-size: 40px 40px;
    }
  }

  &__item {
    padding: 47px 0 47px 64px;

    @media (max-width: 1070px) {
      padding-left: 56px;
    }

    @media (max-width: 1010px) {
      padding-left: 0;
    }

    @media (max-width: 860px) {
      padding: 12px 0 12px 56px;
    }

    &--messenger {
      background: $ic-messenger no-repeat center left;
      background-size: 48px 48px;

      @media (max-width: 1070px) {
        background-size: 40px 40px;
      }

      @media (max-width: 1010px) {
        background: none;
      }

      @media (max-width: 860px) {
        background: $ic-messenger no-repeat center left;
        background-size: 32px 32px;
      }
    }

    &--email {
      background: $ic-email no-repeat center left;
      background-size: 48px 48px;

      @media (max-width: 1070px) {
        background-size: 40px 40px;
      }

      @media (max-width: 1010px) {
        background: none;
      }

      @media (max-width: 860px) {
        background: $ic-email no-repeat center left;
        background-size: 32px 32px;
      }
    }

    &--phone {
      background: $ic-phone no-repeat center left;
      background-size: 48px 48px;

      @media (max-width: 1070px) {
        background-size: 40px 40px;
      }

      @media (max-width: 1010px) {
        background: none;
      }

      @media (max-width: 860px) {
        background: $ic-phone no-repeat center left;
        background-size: 32px 32px;
      }
    }

    &Label {
      display: block;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 14px;
      letter-spacing: 0.16px;
      color: rgba(0, 0, 0, 0.56);
    }

    &Link {
      display: block;
      font-size: 20px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 20px;
      letter-spacing: 0.16px;
      color: $clr-blue;

      &--slim {
        letter-spacing: -0.4px;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
}