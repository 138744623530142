
.Footer {
  height: 48px;
  background: $clr-blue;

  @media (max-width: 690px) {
    margin-top: 48px;
    position: relative;
  }

  &__menu {
    float: left;
    list-style: none;
    padding: 14px 0 16px;

    @media (max-width: 690px) {
      position: absolute;
      float: none;
      width: 100%;
      top: -48px;
      left: 0;
      background: $clr-blue-light;
      text-align: center;
    }

    &Item {
      float: left;
      font-size: 15px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 0.2px;
      color: $clr-white;
      line-height: 18px;
      margin-right: 32px;

      @media (max-width: 690px) {
        float: none;
        display: inline-block;
        color: $clr-blue;
        margin: 0 16px;
      }

      @media (max-width: 550px) {
        margin: 0 8px;
      }

      @media (max-width: 400px) {
        margin: 0 2px;
        font-size: 12px;
      }

      &Link {
        color: $clr-white;
        padding: 4px;

        @media (max-width: 690px) {
          color: $clr-blue;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  &__copyright {
    float: right;
    line-height: 18px;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.2px;
    color: rgba(255, 255, 255, 0.72);
    margin: 11px 68px 11px 0;
    padding: 4px;
    position: relative;

    &:hover {
      text-decoration: none;
    }

    strong {
      color: $clr-white;
      font-weight: normal;
    }

    @media (max-width: 690px) {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      text-align: right;
      padding-right: 72px;

      span {
        float: left;
      }
    }

    @media (max-width: 400px) {
      font-size: 14px;
    }

    &:after {
      content: "";
      display: block;
      background: $clr-white $ic-author center no-repeat;
      background-size: 31px 34px;
      width: 56px;
      height: 56px;
      position: absolute;
      right: -68px;
      bottom: -11px;
      box-shadow: 0 2px 8px 0 rgba(23, 55, 94, 0.50);

      @media (max-width: 690px) {
        right: 0;
      }
    }
  }
}