
.TrainingHero {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5) 77%), radial-gradient(circle at 33% 31%, rgb(109, 76, 65), rgb(94, 64, 55) 33%, rgb(62, 39, 35));
  margin-top: -72px;
  padding: 72px 0 40px;
  text-align: center;

  .Difficulty {
    margin: auto;
  }

  h1 {
    font-size: 60px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 60px;
    letter-spacing: 0.27px;
    text-align: center;
    color: rgb(216, 216, 216);

    @media (max-width: 550px) {
      font-size: 38px;
      line-height: 44px;
    }
  }

  p {
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 34px;
    letter-spacing: 0.11px;
    text-align: center;
    color: $clr-white-secondary;
    padding-top: 16px;
    max-width: 620px;
    margin: auto;

    @media (max-width: 550px) {
      font-size: 20px;
      line-height: 26px;
    }
  }
}