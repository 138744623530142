
.Hero {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)), radial-gradient(circle at 33% 31%, rgb(109, 76, 65), rgb(94, 64, 55) 33%, rgb(62, 39, 35));
  position: relative;
  padding: 168px 0 80px;
  margin-top: -72px;
  text-align: center;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    height: 482px;
    transform: translate(-50%, -50%);
    opacity: .16;
    -webkit-filter: blur(50px);
    filter: blur(50px);
    background-color: rgb(255, 169, 142);
    border-radius: 50%;
    z-index: 0;
  }

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 400px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;

    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#2d1e1b+0,2d1e1b+100,2d1e1b+100&0+0,1+57 */
    background: -moz-linear-gradient(top, rgba(45, 30, 27, 0) 0%, rgba(45, 30, 27, 1) 57%, rgba(45, 30, 27, 1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(45, 30, 27, 0) 0%, rgba(45, 30, 27, 1) 57%, rgba(45, 30, 27, 1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(45, 30, 27, 0) 0%, rgba(45, 30, 27, 1) 57%, rgba(45, 30, 27, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#002d1e1b', endColorstr='#2d1e1b', GradientType=0); /* IE6-9 */
  }

  .Container {
    position: relative;
    z-index: 2;
  }

  h1 {
    background: url("../../assets/title.png") no-repeat center;
    background-size: contain;
    text-indent: -9999px;
    height: 160px;
    text-align: center;
    color: $clr-white;
    margin: auto;
    opacity: 0;

    @media(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      background: url("../../assets/title@2x.png") no-repeat center;
      background-size: contain;
    }
  }

  p {
    color: $clr-white-secondary;
    max-width: 640px;
    margin: auto;
    padding: 24px 0 40px;
    opacity: 0;
  }

  .Button {
    opacity: 0;
  }

  &__boards {
    opacity: 0;

    &Title {
      font-size: 24px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 34px;
      letter-spacing: 0.32px;
      text-align: center;
      color: $clr-white-72;

      br {
        display: none;
      }
    }

    &List {
      margin-top: 16px;
      height: 36px;
      overflow: hidden;
      position: relative;
      background: #2D1E1B;

      &:after {
        content: "";
        display: block;
        width: 20%;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;

        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#2d1e1b+0,2d1e1b+100&0+0,1+100 */
        background: -moz-linear-gradient(left, rgba(45, 30, 27, 0) 0%, rgba(45, 30, 27, 1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(45, 30, 27, 0) 0%, rgba(45, 30, 27, 1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(45, 30, 27, 0) 0%, rgba(45, 30, 27, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#002d1e1b', endColorstr='#2d1e1b', GradientType=1); /* IE6-9 */
      }

      &:before {
        content: "";
        display: block;
        width: 20%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;

        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#2d1e1b+0,2d1e1b+100&1+0,0+100 */
        background: -moz-linear-gradient(left, rgba(45, 30, 27, 1) 0%, rgba(45, 30, 27, 0) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(45, 30, 27, 1) 0%, rgba(45, 30, 27, 0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(45, 30, 27, 1) 0%, rgba(45, 30, 27, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2d1e1b', endColorstr='#002d1e1b', GradientType=1); /* IE6-9 */
      }
    }

    &Item {
      display: inline-block;
      font-size: 15px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 0.2px;
      color: $clr-white-72;
      height: 36px;
      line-height: 36px;
      margin: 0 20px;

      &--beastmaker1000 {
        background: $ic-board--Beastmaker1000 no-repeat center left;
        background-size: 36px 36px;
      }

      &--beastmaker2000 {
        background: $ic-board--Beastmaker2000 no-repeat center left;
        background-size: 36px 36px;
      }

      &--megarail {
        background: $ic-board--Megarail no-repeat center left;
        background-size: 36px 36px;
      }

      &--antMini {
        background: $ic-board--AntMini no-repeat center left;
        background-size: 36px 36px;
      }

      &--woodGripsCompact {
        background: $ic-board--WoodGripsCompact no-repeat center left;
        background-size: 36px 36px;
      }

      &--unlimited {
        background: $ic-board--Unlimited no-repeat center left;
        background-size: 36px 36px;
      }

      &--woodBoard {
        background: $ic-board--WoodBoard no-repeat center left;
        background-size: 36px 36px;
      }

      &--woodBoardHobby {
        background: $ic-board--WoodBoardHobby no-repeat center left;
        background-size: 36px 36px;
      }

      &--woodBoardProfi {
        background: $ic-board--WoodBoardProfi no-repeat center left;
        background-size: 36px 36px;
      }

      &--workshopErgo {
        background: $ic-board--WorkshopErgo no-repeat center left;
        background-size: 36px 36px;
      }

      &--simulator3d {
        background: $ic-board--Simulator3d no-repeat center left;
        background-size: 36px 36px;
      }

      &--crux {
        background: $ic-board--Crux no-repeat center left;
        background-size: 36px 36px;
      }

      &--project {
        background: $ic-board--Project no-repeat center left;
        background-size: 36px 36px;
      }

      &--strongAnt3 {
        background: $ic-board--StrongAnt3 no-repeat center left;
        background-size: 36px 36px;
      }

      &--crimpTravel {
        background: $ic-board--CrimpTravel no-repeat center left;
        background-size: 36px 36px;
      }

      &--gamechanger {
        background: $ic-board--Gamechanger no-repeat center left;
        background-size: 36px 36px;
      }

      &--zlagboardpro2 {
        background: $ic-board--Zlagboardpro2 no-repeat center left;
        background-size: 36px 36px;
      }

      &--woodgripsdeluxe2 {
        background: $ic-board--Woodgripsdeluxe2 no-repeat center left;
        background-size: 36px 36px;
      }

      &--linebreakerbase {
        background: $ic-board--Linebreakerbase no-repeat center left;
        background-size: 36px 36px;
      }

      &--kraxlboardrock {
        background: $ic-board--Kraxlboardrock no-repeat center left;
        background-size: 36px 36px;
      }

      &--whiteOakHangboard {
        background: $ic-board--WhiteOakHangboard no-repeat center left;
        background-size: 36px 36px;
      }

      &--whiteOakPortable {
        background: $ic-board--WhiteOakPortable no-repeat center left;
        background-size: 36px 36px;
      }

      &--egurreHangboard {
        background: $ic-board--EgurreHangboard no-repeat center left;
        background-size: 36px 36px;
      }

      &--balknaSummit {
        background: $ic-board--BalknaSummit no-repeat center left;
        background-size: 36px 36px;
      }

      &Title {
        padding-left: 44px;
      }
    }
  }

  @media (max-width: 550px) {
    margin-top: -95px;
    padding: 128px 0 40px;


    h1 {
      height: auto;
      text-indent: initial;
      background: none;
      font-size: 40px;
      line-height: 46px;

      span {
        color: rgb(237, 220, 186);
      }
    }

    p {
      padding: 24px 0 40px;
      font-size: 16px;
      line-height: 24px;
    }

    &__boards {

      &Title {
        font-size: 18px;
        line-height: 26px;

        br {
          display: block;
        }
      }

      &Item {

        svg {
          transform: scale3d(0.8, 0.8, 1);
        }

        &Title {

        }
      }
    }
  }
}

html .Hero {

  h1, p, .Button {
    animation: heroDown 0.6s ease-in-out forwards;
    animation-delay: 2s;

    @media (max-width: 550px) {
      animation: none;
      opacity: 1;
    }
  }

  p {
    animation-delay: 2.1s;

    @media (max-width: 550px) {
      animation: none;
      opacity: 1;
    }
  }

  .Button {
    animation-delay: 2.2s;

    @media (max-width: 550px) {
      animation: none;
      opacity: 1;
    }
  }

  &__boards {
    animation: heroFade 0.6s ease-in-out forwards;
    animation-delay: 3s;

    @media (max-width: 550px) {
      animation: none;
      opacity: 1;
    }
  }
}

@keyframes heroDown {
  0% {
    opacity: 0;
    transform: translateY(-4px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes heroFade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}