
.Blog {
  padding: 80px 0;
  text-align: center;

  h3 {
    color: $clr-text-primary;
  }

  p {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 24px;
    letter-spacing: 0.24px;
    text-align: center;
    color: $clr-text-secondary;
    max-width: 460px;
    margin: 8px auto 25px;
    display: block;
  }

  @media (max-width: 680px) {
    .medium-widget-article__row .medium-widget-article__item:last-child {
      display: none;
    }
  }

  @media (max-width: 550px) {
    padding: 40px 0 25px;
  }
}