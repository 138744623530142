
.TrainingAuthor {
  display: block;
  padding: 24px;
  width: 100%;

  @media (max-width: 820px) {
    padding: 24px 8px;
  }

  @media (max-width: 550px) {
    padding: 24px 8px 8px;
  }

  img,
  &__placeholder {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: $clr-blue-light;
    margin: auto;
  }

  &__title {
    padding-top: 8px;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 22px;
    letter-spacing: 0.55px;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    white-space: nowrap;
    height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__subtitle {
    padding-top: 0;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 13px;
    letter-spacing: 0.44px;
    text-align: center;
    color: rgba(0, 0, 0, 0.54);
  }
}

.TrainingDuration {
  display: block;
  padding: 24px;
  width: 100%;

  @media (max-width: 820px) {
    padding: 24px 0;
  }

  @media (max-width: 550px) {
    padding: 16px 8px;
  }

  &__chart {
    width: 100%;
    max-width: 156px;
    margin: 16px auto 24px;
    height: 8px;
    border-radius: 4px;
    background-color: rgba(153, 153, 153, 0.4);

    &Thumb {
      display: block;
      height: 100%;
      background: $clr-blue;
      border-radius: 4px;
    }
  }

  &__time {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 16px;
    letter-spacing: 0.55px;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    white-space: nowrap;
    height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__subtitle {
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 13px;
    letter-spacing: 0.44px;
    text-align: center;
    color: rgba(0, 0, 0, 0.54);
    padding-top: 2px;

    strong {
      font-weight: normal;
      color: $clr-blue
    }

    span {
      color: #a2a2a2;
    }
  }
}

.TrainingBoard {
  display: block;
  padding: 24px;
  width: 100%;

  @media (max-width: 820px) {
    padding: 24px 8px;
  }

  @media (max-width: 550px) {
    padding: 16px 8px;
  }

  &__title {
    padding-top: 8px;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 16px;
    letter-spacing: 0.55px;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    white-space: nowrap;
    height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  &__subtitle {
    padding-top: 2px;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 13px;
    letter-spacing: 0.44px;
    text-align: center;
    color: rgba(0, 0, 0, 0.54);
  }

  &__board {
    display: block;
    width: 40px;
    height: 40px;
    margin: auto;

    &--beastmaker1000 {
      background: $ic-board--Beastmaker1000 no-repeat center left;
      background-size: 40px 40px;
    }

    &--beastmaker2000 {
      background: $ic-board--Beastmaker2000 no-repeat center left;
      background-size: 40px 40px;
    }

    &--megarail {
      background: $ic-board--Megarail no-repeat center left;
      background-size: 40px 40px;
    }

    &--antMini {
      background: $ic-board--AntMini no-repeat center left;
      background-size: 40px 40px;
    }

    &--woodGripsCompact {
      background: $ic-board--WoodGripsCompact no-repeat center left;
      background-size: 40px 40px;
    }

    &--unlimited {
      background: $ic-board--Unlimited no-repeat center left;
      background-size: 40px 40px;
    }

    &--woodBoard {
      background: $ic-board--WoodBoard no-repeat center left;
      background-size: 40px 40px;
    }
  }
}

.TrainingCols {
  display: flex;
  width: 100%;
  max-width: 840px;
  margin: auto;
  @include clear;

  @media (max-width: 620px) {
    display: block;
  }
}

.TrainingLine {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.TrainingReviews {
  padding-top: 16px;
  text-align: center;

  h2 {
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 34px;
    letter-spacing: 0.11px;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    padding-bottom: 4px;
  }

  &__list {
    margin-top: 24px;
    overflow-x: auto;
    @include scrollbar(auto, 4px, $clr-blue);

    &Shadow {
      position: relative;

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 156px;
        height: calc(100% - 24px);
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0), $clr-white 76%);

        @media (max-width: 550px) {
          width: 24px;
        }
      }
    }
  }

  &__scroll {
    display: flex;
    padding-bottom: 24px;
    padding-right: 156px;
  }

  &__item {
    display: block;
    width: 300px;
    padding: 8px 12px 12px;
    border-radius: 2px;
    background-color: rgb(242, 242, 242);
    text-align: left;
    margin-right: 16px;
    @include clear;

    &Message {
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 17px;
      letter-spacing: 0.44px;
      color: rgba(0, 0, 0, 0.87);
      padding-bottom: 8px;
    }

    &Author {
      @include clear;
      float: left;
      width: 50%;

      img {
        width: 20px;
        height: 20px;
        display: block;
        float: left;
        margin-right: 8px;
        border-radius: 50%;
      }

      span {
        float: left;
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 20px;
        letter-spacing: 0.44px;
        color: rgba(0, 0, 0, 0.87);
      }
    }

    &Date {
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 20px;
      letter-spacing: 0.44px;
      text-align: right;
      color: rgba(0, 0, 0, 0.4);
      float: left;
      width: 50%;
    }
  }

}

.Training__button {
  text-align: center;
  padding: 40px 0 80px;
}