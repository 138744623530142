
html {
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  // (Chrome 81 workaround)
  // font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-family: -apple-system, Helvetica, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 400;
  quotes: "“" "”";
}

* {
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: 'liga', 'kern';
}

h1 {
  display: block;
  margin: 0;
  font-size: 72px;
  line-height: 80px;
  letter-spacing: 0.32px;
  font-weight: 900;
}

h2 {
  font-size: 56px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 64px;
  letter-spacing: 0.24px;
  text-align: center;
  color: $clr-text-primary;

  @media (max-width: 550px) {
    font-size: 40px;
    line-height: 46px;
  }
}

h3 {
  font-size: 40px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 56px;
  letter-spacing: 0.16px;
  text-align: center;

  @media (max-width: 550px) {
    font-size: 32px;
    line-height: 40px;
  }
}

p.Lead {
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 28px;
  letter-spacing: 0.16px;
  text-align: center;
  color: $clr-text-secondary;
  display: block;
  max-width: 566px;
  margin: 16px auto;

  @media (max-width: 550px) {
    font-size: 16px;
    line-height: 24px;
  }

  &--small {
    font-size: 16px;
    line-height: 24px;
    margin: 8px auto;
  }
}

p {
  display: block;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 34px;
  letter-spacing: 0.11px;
  text-align: center;
}

a {
  color: $clr-blue;

  &:hover {
    text-decoration: underline;
  }
}