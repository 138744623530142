
.PasswordRecovery {
  padding: 64px 0 64px;
  min-height: 100vh;

  .Container {
    text-align: center;
  }

  &__content {
    background: transparent url("../../assets/icon.png") no-repeat center top;
    background-size: 88px 88px;
    padding-top: 92px;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);

    @media(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      background: transparent url("../../assets/icon@2x.png") no-repeat center top;
      background-size: 88px 88px;
    }

    @media (max-height: 600px) {
      position: static;
      transform: none;
    }
  }

  &__alert {
    display: block;
    background: #417505;
    padding: 16px;
    margin: auto;
    max-width: 340px;
    border-radius: 4px;
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;

    &--error {
      background: #d62a37;
    }

    a {
      color: #ffffff;
      font-weight: bold;
    }

    strong {
      display: block;
    }
  }

  h1 {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 22px;
    letter-spacing: 0.24px;
    text-align: center;
    color: $clr-text-primary;
    padding-bottom: 16px;
  }

  p {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: normal;
    text-align: center;
    color: $clr-text-primary-72;
  }

  .form {
    padding: 24px 0;
    display: block;
    text-align: center;
    width: 100%;
  }

  input {
    height: 46px;
    border-radius: 4px;
    border: solid 1px rgb(216, 216, 216);
    background-color: $clr-white;
    width: 100%;
    max-width: 340px;
    padding: 15px 16px;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 16px;
    letter-spacing: 0.48px;
    color: $clr-text-primary;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    margin-bottom: 12px;

    &:focus {
      border: solid 2px $clr-blue;
      padding: 14px 15px;
      outline: none;
    }
  }

  button {
    height: 46px;
    border-radius: 23px;
    background-color: $clr-blue;
    display: block;
    width: 100%;
    max-width: 340px;
    margin: auto;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 45px;
    padding: 0 0 1px;
    letter-spacing: 0.4px;
    text-align: center;
    color: $clr-white;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
    cursor: pointer;

    &[disabled] {
      background-color: $clr-blue-light;
      box-shadow: none;
    }

    &:focus {
      outline: none;
    }
  }
}
