
.FeaturesSlider {
  padding: 80px 0;

  @media (max-width: 550px) {
    padding: 40px 0;
  }

  &--animateRight &__container &__item {
    transform: translate3d(-100%, 0, 0);
  }

  &--animateRight &__container &__item:nth-of-type(1) {
    opacity: 0;
  }

  &--animateRight &__container &__item:nth-of-type(2) {
    opacity: 0;
  }

  &--animateRight &__container &__item:nth-of-type(3) {
    opacity: 0.12;
  }

  &--animateRight &__container &__item:nth-of-type(4) {
    opacity: 0.4;
  }

  &--animateRight &__container &__item:nth-of-type(5) {
    opacity: 1;
  }

  &--animateRight &__container &__item:nth-of-type(6) {
    opacity: 0.4;
  }

  &--animateRight &__container &__item:nth-of-type(7) {
    opacity: 0.12;
  }

  &--animateLeft &__container &__item {
    transform: translate3d(100%, 0, 0);
  }

  &--animateLeft &__container &__item:nth-of-type(1) {
    opacity: 0.12;
  }

  &--animateLeft &__container &__item:nth-of-type(2) {
    opacity: 0.4;
  }

  &--animateLeft &__container &__item:nth-of-type(3) {
    opacity: 1;
  }

  &--animateLeft &__container &__item:nth-of-type(4) {
    opacity: 0.4;
  }

  &--animateLeft &__container &__item:nth-of-type(5) {
    opacity: 0.12;
  }

  &--animateLeft &__container &__item:nth-of-type(6) {
    opacity: 0;
  }

  &--animateLeft &__container &__item:nth-of-type(7) {
    opacity: 0;
  }

  &--noAnimate &__container &__item {
    transition: none !important;
  }

  &--noAnimate &__container {
    opacity: 0.001;
  }

  &__outer {
    position: relative;
  }

  @media (max-width: 1184px) {
    .Container {
      overflow: hidden;
    }
  }

  h2 {
    text-align: center;
    color: $clr-text-primary;
  }

  p {
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 34px;
    letter-spacing: 0.11px;
    text-align: center;
    color: $clr-text-secondary;
    padding: 24px 0 0 0;

    @media (max-width: 550px) {
      font-size: inherit;
      line-height: 24px;
      letter-spacing: inherit;
    }
  }

  &__container {
    display: flex;
    padding: 40px 0 20px;
    position: relative;

    &Relative {
      position: relative;
      width: 1729px;
      left: 50%;
      transform: translateX(-50%);

      @media (max-width: 550px) {
        width: 1288px;
      }
    }

    &--ghost {
      display: flex;
      padding: 40px 0 20px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 1;

      &Hidden {
        opacity: 0;
      }
    }
  }

  &__button {
    position: absolute;
    width: 64px;
    height: 64px;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    top: calc(50% - 32px);
    text-indent: -9999px;
    appearance: none;
    border: none;
    cursor: pointer;
    z-index: 1;
    outline: none;

    @media (max-width: 550px) {
      width: 56px;
      height: 56px;
    }

    &:active {
      background-color: rgba(0, 0, 0, 0.5);
    }

    &--previous {
      left: 32px;
      background-image: $ic-previous;
      background-size: 48px 48px;
      background-position: center;

      @media (max-width: 640px) {
        left: 0;
      }
    }

    &--next {
      right: 32px;
      background-image: $ic-next;
      background-size: 48px 48px;
      background-position: center;

      @media (max-width: 640px) {
        right: 0;
      }
    }
  }

  &__item {
    display: block;
    width: 100%;
    padding: 0 12px;
    transition: all ease-in-out 500ms;
    user-select: none;

    &:nth-of-type(1) {
      opacity: 0;
    }

    &:nth-of-type(2) {
      opacity: 0.12;
    }

    &:nth-of-type(3) {
      opacity: 0.4;
    }

    &:nth-of-type(4) {
      opacity: 1;
    }

    &:nth-of-type(5) {
      opacity: 0.4;
    }

    &:nth-of-type(6) {
      opacity: 0.12;
    }

    &:nth-of-type(7) {
      opacity: 0;
    }
  }

  &__phone {
    display: block;
    width: 223px;
    height: 446px;
    margin: auto;
    position: relative;

    @media (max-width: 550px) {
      width: 160px;
      height: 320px;
    }

    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background: url("../../../assets/features-phone.png") no-repeat center;
      background-size: 223px 446px;
      position: absolute;
      top: 0;
      left: 0;

      @media (max-width: 550px) {
        background-size: 160px 320px;
      }

      @media(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background: url("../../../assets/features-phone@2x.png") no-repeat center;
        background-size: 223px 446px;

        @media (max-width: 550px) {
          background-size: 160px 320px;
        }
      }
    }
  }

  &__image {
    display: block;
    width: 194px;
    position: absolute;
    top: 13px;
    left: 15px;
    border-radius: 10px;

    img {
      position: absolute;
      inset: 0;
      width: 100%;
      height: auto;
    }

    @media (max-width: 550px) {
      width: 140px;
      top: 8px;
      left: 10px;
    }
  }

  &__info {
    max-width: 332px;
    padding: 0 16px;
    margin: auto;

    h3 {
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 24px;
      letter-spacing: 0.24px;
      text-align: center;
      color: $clr-blue;
    }

    p {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 20px;
      letter-spacing: 0.21px;
      text-align: center;
      color: rgba(0, 0, 0, 0.54);
      padding-top: 11px;
      min-height: 71px;
    }
  }
}
