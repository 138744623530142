
.FeaturesHeading {
  text-align: center;
  padding: 448px 0 0 0;
  background: url("../../../assets/mockups.jpg") no-repeat center -212px;
  background-size: 2010px 620px;
  margin-top: -128px;

  @media(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    background-image: url("../../../assets/mockups@2x.jpg");
    background-size: 2010px 620px;
  }

  @media (max-width: 550px) {
    background-image: url("../../../assets/mockups.jpg");
    background-size: 1005px 310px;
    background-position: center -64px;
    padding-top: 280px;
  }

  h1 {
    @media (max-width: 550px) {
      font-size: 40px;
      line-height: 46px;
    }
  }

  p.Lead {
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0.11px;

    @media (max-width: 550px) {
      font-size: inherit;
      line-height: 24px;
      letter-spacing: inherit;
    }
  }
}
