
.FeatureCard {
  padding-top: 80px;

  @media (max-width: 550px) {
    padding-top: 40px;
  }

  &--training &__banner,
  &--trainings &__banner {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5) 77%), radial-gradient(circle at 33% 31%, rgb(109, 76, 65), rgb(94, 64, 55) 33%, rgb(62, 39, 35));
  }

  &--stats &__banner {
    background-color: rgb(6, 6, 6);
  }

  &--trainings &__banner {
    position: relative;
    overflow: hidden;

    @media (max-width: 660px) {
      padding-bottom: 128px;
    }

    @media (max-width: 480px) {
      padding-bottom: 96px;
    }

    h2,
    p {
      position: relative;
      z-index: 1;
    }
  }

  &__banner {
    display: flex;
    flex-direction: column;
    padding: 48px 48px 0;
    border-radius: 4px;
    overflow: hidden;

    @media (max-width: 550px) {
      padding: 24px 24px 0;
    }

    @media (max-width: 480px) {
      padding: 16px 16px 0;
    }

    h2 {
      font-size: 40px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 56px;
      letter-spacing: 0.16px;
      text-align: center;
      color: $clr-white;

      @media (max-width: 550px) {
        font-size: 32px;
        line-height: 40px;
        padding-bottom: 8px;
      }

      @media (max-width: 480px) {
        font-size: 28px;
        line-height: 36px;
      }
    }

    p {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 24px;
      letter-spacing: 0.24px;
      text-align: center;
      color: $clr-white-secondary;
      padding-bottom: 48px;

      @media (max-width: 550px) {
        padding-bottom: 24px;
      }

      @media (max-width: 480px) {
        padding-bottom: 16px;
      }
    }

    img {
      display: block;
      margin: auto;
      width: 100%;
      max-width: 620px;

      @media (max-width: 830px) {
        max-width: 520px;
      }
    }
  }

  &__panel {
    display: block;
    float: left;
    width: 33.33%;
    padding: 32px 16px 0 48px;

    @media (max-width: 830px) {
      width: 100%;
      float: none;
    }

    &List {
      margin: 0 -16px;
      @include clear;
    }

    &--sound {
      background-image: $ic-sound;
      background-size: 24px 24px;
    }

    &--pause {
      background-image: $ic-pause;
      background-size: 24px 24px;
    }

    &--tv {
      background-image: $ic-tv;
      background-size: 24px 24px;
    }

    &--calendar {
      background-image: $ic-calendar;
      background-size: 24px 24px;
    }

    &--percentile {
      background-image: $ic-percentile;
      background-size: 24px 24px;
    }

    &--badge {
      background-image: $ic-badge;
      background-size: 24px 24px;
    }

    &--repository {
      background-image: $ic-repository;
      background-size: 24px 24px;
    }

    &--info {
      background-image: $ic-info;
      background-size: 24px 24px;
    }

    &--create {
      background-image: $ic-create;
      background-size: 24px 24px;
    }

    background-position: 16px 32px;
    background-repeat: no-repeat;

    h3 {
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 24px;
      letter-spacing: 0.24px;
      color: $clr-blue;
      text-align: left;
    }

    p {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 20px;
      letter-spacing: 0.21px;
      color: rgba(0, 0, 0, 0.54);
      text-align: left;
      padding-top: 11px;
    }
  }

  &__image {
    display: block;
    margin: 0 auto -4px;
    width: 100%;
    max-width: 620px;

    @media (max-width: 830px) {
      max-width: 520px;
    }
  }

  &--stats &__image {
    padding-bottom: 76px;

    @media (max-width: 550px) {
      padding-bottom: 24px;
    }
  }

  &--trainings &__image {
    display: flex;
    align-items: flex-end;
    max-width: none;
    width: auto;
    height: 327px;
    z-index: 0;
    margin-top: -128px;

    @media (max-width: 660px) {
      margin-top: -280px;
    }

    @media (max-width: 480px) {
      margin-top: -220px;
      height: 221px;
    }

    img {
      object-fit: cover;
      object-position: center;
    }
  }
}
