
.StoryAbout {
  text-align: center;
  padding-bottom: 40px;

  p.Lead {
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0.11px;
  }

  @media (max-width: 550px) {
    padding-bottom: 0;

    h1 {
      font-size: 40px;
      line-height: 46px;
    }

    p.Lead {
      padding-top: 0;
      font-size: inherit;
      line-height: 24px;
      letter-spacing: inherit;
    }

  }
}