
.Difficulty {
  display: block;
  width: 64px;
  height: 64px;

  &--5a {
    background: $ic-difficulty-5a no-repeat center;
    background-size: 64px 64px;
  }

  &--5b {
    background: $ic-difficulty-5b no-repeat center;
    background-size: 64px 64px;
  }

  &--5c {
    background: $ic-difficulty-5c no-repeat center;
    background-size: 64px 64px;
  }

  &--6a {
    background: $ic-difficulty-6a no-repeat center;
    background-size: 64px 64px;
  }

  &--6b {
    background: $ic-difficulty-6b no-repeat center;
    background-size: 64px 64px;
  }

  &--6c {
    background: $ic-difficulty-6c no-repeat center;
    background-size: 64px 64px;
  }

  &--7a {
    background: $ic-difficulty-7a no-repeat center;
    background-size: 64px 64px;
  }

  &--7b {
    background: $ic-difficulty-7b no-repeat center;
    background-size: 64px 64px;
  }

  &--7c {
    background: $ic-difficulty-7c no-repeat center;
    background-size: 64px 64px;
  }

  &--8a {
    background: $ic-difficulty-8a no-repeat center;
    background-size: 64px 64px;
  }

  &--8b {
    background: $ic-difficulty-8b no-repeat center;
    background-size: 64px 64px;
  }

  &--8c {
    background: $ic-difficulty-8c no-repeat center;
    background-size: 64px 64px;
  }

  &--9a {
    background: $ic-difficulty-9a no-repeat center;
    background-size: 64px 64px;
  }

  &--9b {
    background: $ic-difficulty-9b no-repeat center;
    background-size: 64px 64px;
  }

  &--9c {
    background: $ic-difficulty-9c no-repeat center;
    background-size: 64px 64px;
  }

  &--large {
    width: 160px;
    height: 160px;
    background-size: 160px 160px;

    @media (max-width: 550px) {
      width: 120px;
      height: 120px;
      background-size: 120px 120px;
    }
  }
}