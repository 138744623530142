.FeaturesHero {
  padding: 80px 0 500px 0;
  text-align: center;
  background: url("../../../assets/mockups.jpg") no-repeat center bottom;
  background-size: 2010px 620px;
  margin-bottom: 120px;

  @media(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    background-image: url("../../../assets/mockups@2x.jpg");
    background-size: 2010px 620px;
  }

  .Button {
    display: inline-block;
    margin-top: 8px;
  }

  .Container {
    position: relative;
  }

  @media (max-width: 550px) {
    padding: 40px 0 280px 0;
    background-size: 1005px 310px;
    margin-bottom: 56px;

    @media(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      background-image: url("../../../assets/mockups.jpg");
      background-size: 1005px 310px;
    }
  }
}