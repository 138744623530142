
.Button {
  display: inline-block;

  &__content {
    position: relative;
    z-index: 1;
  }

  &--outline {
    height: 46px;
    border-radius: 23px;
    border: solid 1.5px $clr-blue;
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.4px;
    text-align: center;
    color: $clr-blue;
    line-height: 20px;
    padding: 10px 40px;

    &Dark {
      color: $clr-white;
      border: solid 1.5px $clr-white;
      background-color: rgba(0, 0, 0, 0.48);
    }
  }

  &--hero {
    position: relative;
    height: 46px;
    border-radius: 23px;
    box-shadow: 0 4px 16px 0 rgba(27, 59, 97, 0.64);
    background: $clr-blue;
    padding: 13px 24px 15px;
    font-size: 15px;
    line-height: normal;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.4px;
    text-align: center;
    color: $clr-white;
    overflow: hidden;
    transition: transform .2s ease-in-out;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      box-shadow: inset 0 1px 1px 0 rgba(255, 255, 255, 0.24);
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.1));
    }

    &:active {
      border: none;
      padding: 13px 24px 15px;
      transform: scale3d(0.94, 0.94, 1);
    }
  }

  &:hover {
    text-decoration: none;
  }
}