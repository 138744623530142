
.Preview {
  width: 100%;
  max-width: 931px;
  height: 197px;
  margin: 72px auto 86px;
  position: relative;
  opacity: 0;

  &__overlay {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url("../../assets/phone.png") no-repeat center;
    background-size: contain;

    @media(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      background: url("../../assets/phone@2x.png") no-repeat center;
      background-size: contain;
    }
  }

  &__video {
    width: 100%;
    padding: 0 23px;
    perspective: 1280px;
    position: absolute;

    &:before {
      content: "";
      display: block;
      position: absolute;
      height: 462px;
      width: calc(100% - 122px);
      transform-style: preserve-3d;
      transform: rotate3d(1, 0, 0, 70deg);
      top: -149px;
      left: 60px;
      border-radius: 79px;
      box-shadow: 0 64px 40px 0 rgba(0, 0, 0, 0.64);
    }
  }

  video {
    display: block;
    width: calc(100% - 143px);
    transform-style: preserve-3d;
    transform: rotate3d(1, 0, 0, 70deg);
    top: -117px;
    left: 69px;
    position: absolute;
    border-radius: 42px;
  }

  @media (max-width: 998px) {
    max-width: 614px;
    height: 130px;

    &__video {
      perspective: 738px;

      &:before {
        height: 266px;
        width: calc(100% - 140px);
        transform-style: preserve-3d;
        transform: rotate3d(1, 0, 0, 70deg) scale3d(1.11, 1.08, 1);
        top: -82px;
        left: 70px;
        border-radius: 113px;
      }
    }

    video {
      width: calc(100% - 143px);
      transform-style: preserve-3d;
      transform: rotate3d(1, 0, 0, 70deg) scale3d(1.11, 1.08, 1);
      top: -67px;
      left: 69px;
      position: absolute;
      border-radius: 25px 18px 27px 31px;
    }
  }

  @media (max-width: 700px) {
    max-width: 480px;
    height: 102px;

    &__video {
      perspective: 585px;

      &:before {
        height: 190px;
        width: calc(100% - 140px);
        transform: rotate3d(1, 0, 0, 70deg) scale3d(1.2, 1.08, 1);
        top: -54px;
        left: 70px;
        border-radius: 20px;
      }
    }

    video {
      width: calc(100% - 114px);
      transform: rotate3d(1, 0, 0, 68deg) scale3d(1.12, 1, 1);
      top: -51px;
      left: 57px;
      border-radius: 16px 18px 16px 13px;
    }
  }

  @media (max-width: 550px) {
    max-width: 288px;
    height: 61px;
    margin: 40px auto 64px;
    opacity: 1;
    animation: none !important;

    &__video {
      perspective: 330px;

      &:before {
        height: 106px;
        width: calc(100% - 84px);
        transform: rotate3d(1, 0, 0, 70deg) scale3d(1.2, 1.08, 1);
        top: -30px;
        left: 41px;
        border-radius: 18px;
      }
    }

    video {
      width: calc(100% - 68px);
      transform: rotate3d(1, 0, 0, 68deg) scale3d(1.12, 1, 1);
      top: -31px;
      left: 33px;
      border-radius: 15px 13px 8px 11px;
    }
  }
}

html .Preview {
  animation: preview 1s ease-in-out 500ms forwards;
}

@keyframes preview {
  0% {
    opacity: 0;
    transform: translateY(8px) scale3d(0.96, 0.96, 1);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale3d(1, 1, 1);
  }
}