
.StoryItem {
  margin-bottom: 80px;
  padding-top: 40px;

  &:after {
    content: "";
    display: block;
    clear: both;
  }

  &--right {

    .StoryItem__content {
      float: right;
      width: 50%;

      @media (max-width: 1080px) {
        width: inherit;
        float: inherit;
      }
    }

    .StoryItem__photo {
      width: 50%;

      @media (max-width: 1080px) {
        width: inherit;
      }

      &:before {
        transform: rotate(2deg);
      }
    }
  }

  &__content {
    width: 400px;
    background-color: rgb(246, 246, 246);
    padding: 40px 40px 12px;
    float: left;

    @media (max-width: 1080px) {
      width: 100%;
      max-width: 800px;
      margin: auto;
      float: none;
      padding-bottom: 40px;
    }

    @media (max-width: 550px) {
      padding: 24px 24px 12px;
    }

    @media (max-width: 480px) {
      padding: 16px 16px 12px;
    }

    p {
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 28px;
      letter-spacing: 0.09px;
      color: $clr-text-primary-72;
      text-align: left;
      padding-bottom: 28px;
    }

    ul {
      list-style-position: inside;
      margin-top: -20px;
      padding-bottom: 28px;
      margin-right: -4px;

      li {
        font-size: 13px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 24px;
        letter-spacing: 0.08px;
        color: $clr-text-primary-72;
      }
    }
  }

  &__photo {
    width: calc(100% - 400px);
    float: left;
    position: relative;

    @media (max-width: 1080px) {
      width: 100%;
      float: none;
      max-width: 800px;
      margin: auto;
    }

    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: calc(100% - 32px);
      height: calc(100% - 32px);
      background: url("/storyBig1.jpg") no-repeat center;
      border: solid 16px $clr-white;
      box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.24);
      left: 0;
      top: 0;
      z-index: 0;
    }

    &:before {
      transform: rotate(4deg);
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
    }

    &:after {
      transform: rotate(-3deg);
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16);
    }
  }

  &__image {
    display: block;
    width: 100%;
    position: relative;
    z-index: 5;
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.24);
    border: solid 16px $clr-white;

    img {
      width: 100%;
      object-position: center;
      object-fit: cover;
    }
  }
}

.StoryFooter {
  padding: 40px 0 80px;
  text-align: center;

  @media (max-width: 550px) {
    padding: 0 0 64px;
  }

  p {
    display: block;
    max-width: 566px;
    margin: 16px auto 40px;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0.11px;

    @media (max-width: 550px) {
      padding-top: 0;
      font-size: inherit;
      line-height: 24px;
      letter-spacing: inherit;
      margin-bottom: 24px;
    }
  }
}
