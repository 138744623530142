
.StoryTopPhotos {

  @media (max-width: 550px) {
    height: 200px;
    margin-top: 25px;
  }

  @media (max-width: 480px) {
    height: 156px;
    margin-top: 50px;
  }

  & > .Container {
    position: relative;
    width: 1184px;
    height: 320px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;

    @media (max-width: 1184px) {
      overflow: hidden;
      width: 100%;
    }

    @media (max-width: 550px) {
      transform: translate(-50%, -10%);
      height: 256px;
    }

    @media (max-width: 480px) {
      transform: translate(-50%, -20%);
    }
  }

  &__item {
    display: block;
    width: 272px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16), 0 1px 2px 0 rgba(0, 0, 0, 0.16);
    background-color: $clr-white;
    padding: 16px;
    position: absolute;

    &:nth-child(1) {
      transform: rotate(9deg) translate(-105px, -66%);
    }

    &:nth-child(2) {
      transform: rotate(18deg) translate(-15px, -35%);
      z-index: -1;
    }

    &:nth-child(3) {
      transform: rotate(-11deg) translate(270px, -62%);

      @media (max-width: 420px) {
        display: none;
      }
    }

    &:nth-child(4) {
      transform: rotate(25deg) translate(270px, -77%);

      @media (max-width: 420px) {
        transform: rotate(25deg) translate(100px, -80%);
      }
    }

    &:nth-child(5) {
      transform: rotate(-11deg) translate(579px, -16%);

      @media (max-width: 550px) {
        display: none;
      }
    }

    &:nth-child(6) {
      transform: rotate(16deg) translate(642px, -88%);

      @media (max-width: 550px) {
        display: none;
      }
    }

    &:nth-child(7) {
      transform: rotate(6deg) translate(797px, -65%);
      z-index: -1;

      @media (max-width: 550px) {
        display: none;
      }
    }

    &:nth-child(8) {
      transform: rotate(-4deg) translate(1018px, -45%);
      z-index: -1;

      @media (max-width: 550px) {
        display: none;
      }
    }
  }

  &__photo {
    position: relative;
    display: block;
    width: 100%;
    height: 240px;
    margin-bottom: 16px;
    background-color: rgb(246, 246, 246);

    @media (max-width: 550px) {
      height: 180px;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      inset: 0;
      object-fit: cover;
      object-position: center;
    }
  }

  &__caption {
    display: block;
    height: 14px;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 14px;
    letter-spacing: 0.06px;
    text-align: center;
    color: $clr-text-secondary;
  }
}
