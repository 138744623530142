
.FeaturesBoards {

  h2 {
    @media (max-width: 550px) {
      font-size: 40px;
      line-height: 46px;
    }
  }


  p {
    max-width: 370px;
    margin: auto;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 34px;
    letter-spacing: 0.11px;
    text-align: center;
    color: rgba(0, 0, 0, 0.8);
    padding: 24px 0 0 0;

    @media (max-width: 550px) {
      font-size: inherit;
      line-height: 24px;
      letter-spacing: inherit;
    }
  }

  &__list {
    padding: 32px 0;
    @include clear;
  }

  .Hero__boardsItem {
    display: block;
    width: 33%;
    float: left;
    color: $clr-text-primary;
    line-height: 24px;
    letter-spacing: 0.24px;
    font-weight: 700;
    padding: 0;
    margin: 8px 0;

    @media (max-width: 860px) {
      width: 50%;
    }

    @media (max-width: 568px) {
      width: 100%;
    }

    &Title {
      line-height: 36px;
      padding-left: 52px;
    }
  }
}