
.Instagram {
  height: 400px;
  position: relative;
  text-align: center;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 80px;

  @media (max-width: 680px) {
    height: 320px;
  }

  @media (max-width: 550px) {
    height: 300px;
    margin-bottom: 40px;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.64);
    z-index: 1;
  }

  h3 {
    color: $clr-white;
  }

  p {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 24px;
    letter-spacing: 0.24px;
    text-align: center;
    color: $clr-white-secondary;
    max-width: 460px;
    margin: 8px auto;
    display: block;
  }

  .Button {
    margin-top: 16px;
  }

  &__content {
    position: absolute;
    left: 0;
    width: 100%;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 2;
    padding: 0 16px;
  }

  &__feed {
    width: 1600px;
    height: 400px;
    display: block;
    background-size: 1600px 400px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("https://core.fingy.app/api/web/instagram/t/1");
    filter: blur(20px);
    transition: filter .5s linear, transform .5s linear;
    position: relative;

    & > div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: 1600px 400px;
      background-repeat: no-repeat;
      background-position: center;
    }

    @media (max-width: 680px) {
      height: 320px;
      width: 1280px;
      background-size: 1280px 320px;

      & > div {
        background-size: 1280px 320px;
      }
    }

    @media (max-width: 550px) {
      height: 300px;
      width: 1200px;
      background-size: 1200px 300px;

      & > div {
        background-size: 1200px 300px;
      }
    }
  }

  &__marquee--loaded &__feed {
    filter: none;

    & > div {
      background-image: url("https://core.fingy.app/api/web/instagram");
      animation: feed 500ms ease-in forwards;
    }
  }
}

@keyframes feed {
  0% {
    background-image: none;
    opacity: 0;
    filter: blur(20px);
    transform: translateZ(0);
  }
  1% {
    background-image: url("https://core.fingy.app/api/web/instagram");
    opacity: 0;
    filter: blur(20px);
    transform: translateZ(0);
  }
  100% {
    background-image: url("https://core.fingy.app/api/web/instagram");
    transform: none;
    opacity: 1;
    filter: none;
  }
}