@mixin clear {
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

@mixin scrollbar($width: 4px, $height: auto, $color: black) {
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    background: none;
    width: $width;
    height: $height;
    border: none;
    -webkit-box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $clr-blue-light;
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &:hover::-webkit-scrollbar-thumb {
    height: $height;
    background-color: $color;
    border: none;
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: darken($color, 20%);
    border: none;
  }
}