

.Stars {
  display: flex;
  width: 152px;
  height: 24px;
  margin: auto;

  &__item {
    display: block;
    width: 24px;
    height: 24px;
    background: $ic-stars-full no-repeat center;
    background-size: 24px 24px;
    margin: 0 4px;

    &--half {
      background: $ic-stars-half no-repeat center;
      background-size: 24px 24px;
    }

    &--empty {
      background: $ic-stars-empty no-repeat center;
      background-size: 24px 24px;
    }
  }
}