
.Navigation {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 72px;
  z-index: 999;
  opacity: 1;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: -1;
  }

  &--dark {

    &:after {
      background-color: rgba(0, 0, 0, 0.92);
      opacity: 1;
    }

    .Navigation {

      &__wrapper {
        border: none;
      }

      &__menu {

        &Item {

          &Link {
            &--promoted {
              color: $clr-white;
              background: $clr-blue;
            }
          }
        }
      }
    }
  }

  &--light {
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.24);

    &:after {
      background-color: rgba(255, 255, 255, 0.96);
      opacity: 1;
    }

    .Navigation {

      &__wrapper {
        border: none;
      }

      &__brand {
        color: $clr-text-primary;
      }

      &__menu {

        &Item {
          color: $clr-text-primary-72;

          &Link {

            &:hover {
              color: $clr-blue;
            }

            &--promoted {
              color: $clr-white;
              background: $clr-blue;

              &:hover {
                color: $clr-white;
              }
            }
          }
        }
      }
    }
  }

  &__helper {
    display: block;
    height: 72px;
    width: 100%;
  }

  &__wrapper {
    border-bottom: 1px solid $clr-separator-dark;
    @include clear;
  }

  &__brand {
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 20px;
    letter-spacing: 0.24px;
    color: $clr-white;
    display: block;
    float: left;
    margin-top: 26px;

    &:hover {
      text-decoration: none;
    }
  }

  &__menu {
    display: block;
    float: right;
    margin-right: -12px;
    @include clear;

    &Item {
      display: block;
      float: left;
      height: 72px;
      line-height: 72px;
      font-size: 15px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 0.2px;
      text-align: right;
      color: $clr-brown-text;
      margin-left: 24px;

      &Link {
        color: inherit;
        padding: 4px;

        &--promoted {
          font-weight: bold;
          color: $clr-white;
          border-radius: 14px;
          padding: 3px 12px 5px;
          margin-left: -8px;
          transition: background-color .1s ease-in-out;
        }

        &:hover {
          text-decoration: none;
          color: $clr-white;
        }

        &--active {
          color: $clr-blue !important;
          font-weight: bold !important;
        }
      }
    }
  }

  @media (max-width: 512px) {
    height: 95px;

    &:after {
      opacity: 1;
      background-color: rgba(255, 255, 255, 0.96);
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.24);
    }

    &__wrapper {
      border-bottom: none;
    }

    .Container {
      padding: 0;
    }

    &__helper {
      height: 95px;
    }

    &__brand {
      line-height: 22px;
      float: none;
      padding: 16px;
      margin: 0;
      color: $clr-text-primary;
    }

    &__menu {
      float: none;
      margin: 0;
      border-top: 1px solid rgba(0, 0, 0, 0.16);

      &Item {
        width: 33.3%;
        height: 40px;
        line-height: 40px;
        margin: 0;
        text-align: center;
        border-left: 1px solid rgba(0, 0, 0, .16);

        &:first-child {
          border-left: none;
        }

        &:last-child {
          position: absolute;
          border-left: none;
          top: 0;
          right: 0;
          width: auto;
          height: auto;
          line-height: normal;
          padding: 13px 16px 0;
        }

        &Link {
          color: $clr-blue;

          &--promoted {
            display: block;
            color: $clr-white;
            background: $clr-blue;
            margin: 0;
          }
        }

        &--active {
          font-weight: normal !important;
        }
      }
    }
  }
}