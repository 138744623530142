@import '~normalize-scss/sass/normalize';

@import "variables";
@import "utils";
@import "typography";

@import "components/button";
@import "components/hero";
@import "components/preview";
@import "components/difficulty";

@import "components/featureCard";

@import "components/layout/layout";
@import "components/layout/navigation";
@import "components/layout/footer";

@import "components/features/hero";
@import "components/features/heading";
@import "components/features/slider";
@import "components/features/boards";

@import "components/story/hero";
@import "components/story/topPhotos";
@import "components/story/about";
@import "components/story/item";

@import "components/blog";
@import "components/instagram";
@import "components/contact";
@import "components/plainText";
@import "components/training";
@import "components/trainingHero";
@import "components/stars";
@import "components/recovery";

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  text-decoration: none;
  border: none;
}

body {
  min-width: 320px;
  overflow-x: hidden;
}
