
.Layout__content {
  min-height: 100vh;
}

.Container {
  display: block;
  width: 100%;
  max-width: 1184px;
  margin: 0 auto;
  padding: 0 32px;

  &:after {
    content: "";
    display: block;
    clear: both;
  }

  @media (max-width: 500px) {
    padding: 0 16px;
  }
}