
.StoryHero {
  margin-top: 64px;
  height: 400px;
  background: url("../../../assets/storyHero.jpg") no-repeat center;
  background-size: cover;
  position: relative;
  text-align: center;
  border-radius: 4px;
  overflow: hidden;

  @media(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    background: url("../../../assets/storyHero@2x.jpg") no-repeat center;
    background-size: cover;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .32);
  }

  h3 {
    color: $clr-white;
  }

  p {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 24px;
    letter-spacing: 0.24px;
    text-align: center;
    color: $clr-white-secondary;
    max-width: 470px;
    margin: 8px auto;
    display: block;
  }

  .Button {
    margin-top: 16px;
  }

  &__content {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
    padding: 0 16px;
  }

  @media (max-width: 680px) {
    height: 320px;
  }

  @media (max-width: 550px) {
    margin-top: 32px;
    height: 300px;
  }
}