
.PlainText {
  padding: 88px 0;

  @media (max-width: 550px) {
    padding: 44px 0;
  }

  h1 {
    text-align: center;
    padding-bottom: 72px;

    @media (max-width: 550px) {
      font-size: 56px;
      line-height: 64px;
      padding-bottom: 28px;
    }

    @media (max-width: 400px) {
      font-size: 40px;
      line-height: 48px;
      padding-bottom: 28px;
    }
  }

  h2 {
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 20px;
    letter-spacing: 0.18px;
    color: $clr-text-primary;
    text-align: left;
    padding-top: 52px;
  }

  p {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 24px;
    letter-spacing: 0.08px;
    color: $clr-text-primary-72;
    text-align: left;
    padding: 16px 0 0 0;
  }

  ul {
    list-style-position: inside;
    padding-top: 8px;

    li {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 24px;
      letter-spacing: 0.08px;
      color: $clr-text-primary-72;
    }
  }
}